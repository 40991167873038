<template>
	<div class="ztnrbg ">
	<div class="sytext">
		<!-- <img src="../../assets/img/xintp/sybj.png" alt=""> -->
         <img src="../../assets/img/xintp/banner.jpg" alt="" style="width:100%;height:auto">
		
	</div>

		<div class="index_mv ztnr">
			<leftMeun name='搜索'></leftMeun>
			<div class="right_nr">
				<rightMeun name='搜索'></rightMeun>
				<div class="list">
					<div class="read_articles" v-if="list.length">
						<div class="newsList" style="flex: 1; width: 100%;">
						<news v-for="(li,index) in list" :key='index' :msg="li" types="bggk" />
						</div>
					</div>
					<div v-else class="read_articles">
						探索中
					</div>
					<div class="pages">
						<Page v-if="count>10" :total="count" @on-change='chan' />
					</div>
				</div>
			</div>
            <newRightMeun></newRightMeun>

		</div>
	</div>
</template>

<script>
	import news from '@/components/tpList.vue'
 import newRightMeun from '@/components/newRightMeun.vue'
	import leftMeun from '@/components/leftMeun.vue'
	import rightMeun from '@/components/rightMeun.vue'

	export default {
		components: {
			leftMeun,
			news,
			rightMeun,
            newRightMeun
		},
		data() {
			return {

				list: [],
				page: 1,
				count: 0
			}
		},
		watch: {
			$route: 'types'
		},

		methods: {
			chan(e) {
				var that = this
				that.page = e
				that.lists()
			},
			lists() {
				var that = this
				that.$api.news({
					page: that.page,
					row: 10,
					search: that.$route.params.searchtext,
				}).then(res => {
					that.$emit('getLoad', true);
					that.list = res.data
					that.count = res.count

					for (let i = 0; i < that.list.length; i++) {
						console.log(646)
						if (that.list[i].fid == 8 || that.list[i].fid == 9) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'bggk'
						}
						if (that.list[i].fid == 3) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'zszx'
						}
						if (that.list[i].fid == 16 || that.list[i].fid == 17) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'zszx'
						}
						if (that.list[i].fid == 18 || that.list[i].fid == 19 || that.list[i].fid == 20) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'gdzn'
						}
						if (that.list[i].catid == 21 || that.list[i].catid == 22 || that.list[i].catid == 23) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'slzj'
						}
						if (that.list[i].fid == 24 || that.list[i].catid == 25 || that.list[i].catid == 26) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'daxt'
						}
						if (that.list[i].fid == 27 || that.list[i].fid == 28 || that.list[i].fid == 29) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'gzzd'
						}
						if (that.list[i].fid == 33) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'fwdt'
						}
					}
				}).catch((error) => {
					// error
					console.log(error)
				})
			},
			types() {
				var that = this
				that.$api.news({
					page: 1,
					row: 10,
					search: that.$route.params.searchtext,
				}).then(res => {
					that.$emit('getLoad', true);
					that.list = res.data
					that.count = res.count
					for (let i = 0; i < that.list.length; i++) {
						console.log(646)
						if (that.list[i].fid == 8 || that.list[i].fid == 9) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'bggk'
						}
						// if (that.list[i].fid == 3) {
						// 	that.list[i].tzfs = 'text'
						// 	that.list[i].typess = 'zszx'
						// }
						if (that.list[i].catid == 14 || that.list[i].catid == 15 || that.list[i].fid == 16 || that.list[i].fid == 17) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'zszx'
							if (that.list[i].catid != 15) {
								that.list[i].tzfs = 'text'
							} else {
								that.list[i].tzfs = 'cont'
							}
						}
						if (that.list[i].fid == 18 || that.list[i].fid == 19 || that.list[i].fid == 20) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'gdzn'
						}
						if (that.list[i].catid == 21 || that.list[i].catid == 22 || that.list[i].catid == 23) {
							that.list[i].tzfs = 'cont'
							that.list[i].typess = 'slzj'
						}
						if (that.list[i].fid == 24 || that.list[i].catid == 25 || that.list[i].catid == 26) {

							that.list[i].typess = 'daxt'
							if (that.list[i].catid == 25) {
								that.list[i].tzfs = 'text'
							} else {
								that.list[i].tzfs = 'cont'
							}
						}
						if (that.list[i].fid == 27 || that.list[i].fid == 28 || that.list[i].fid == 29) {
							that.list[i].tzfs = 'text'
							that.list[i].typess = 'gzzd'
						}
						if (that.list[i].fid == 33) {
							that.list[i].tzfs = 'cont'
							that.list[i].typess = 'fwdt'
						}
					}


					// that.list=that.list.concat(res.data)
				}).catch((error) => {
					// error
					console.log(error)
				})
			}
		},
		mounted() {
			this.types()
		}
	}
</script>

<style>

</style>
